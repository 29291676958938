
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
@import url(https://cdn.jsdelivr.net/npm/bulma@0.8.0/css/bulma.min.css);
@import url(https://use.fontawesome.com/releases/v5.7.0/css/all.css);

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

@font-face {
    font-family: "Beatrice";
    src: url("assets/fonts/Regular/Beatrice-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Beatrice";
    src: url("assets/fonts/Bold/Beatrice-Bold.woff") format("woff");
    font-weight: 700;
    font-style: bold;
}
@font-face {
    font-family: "Beatrice";
    src: url("assets/fonts/Condensed/Beatrice-Light.woff") format("woff");
    font-weight: 200;
    font-style: lighter;
}

@font-face {
    font-family: "ThreeOfNine";
    src: url("assets/fonts/3OF9_NEW.woff") format("woff");
}

@font-face {
    font-family: "ATTAleckSans";
    src: url("assets/fonts/Regular/ATTAleckSans_Rg.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "ATTAleckSans";
    src: url("assets/fonts/Bold/ATTAleckSans_Bd.woff") format("woff");
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: "ATTAleckSansCD";
    src: url("assets/fonts/Condensed/ATTAleckCd_W_Lt.woff") format("woff");
    font-weight: 200;
    font-style: lighter;
}

@font-face {
    font-family: "ATTAleckSansCD";
    src: url("assets/fonts/Condensed/ATTAleckCd_W_Rg.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "ATTAleckSansCD";
    src: url("assets/fonts/Condensed/ATTAleckCd_W_Blk.woff") format("woff");
    font-weight: 700;
    font-style: bold;
}

html {
    overflow-y: hidden !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
}
* h1, h2{
    margin: 0;
    font-family: "Beatrice" !important;
}
* p, span, .mat-tab-label-content, .ag-cell-value, input{
    margin: 0;
    font-family: "Inter" !important;
}
.ag-theme-balham .ag-icon, .ag-header-icon {
    font-family: "agGridBalham" !important;
    color:black !important;
    opacity:1 !important;
}
.ag-theme-balham .ag-header-expand-icon {
    color: white !important;
}
.ag-theme-balham .ag-icon-contracted:before {
    content: "\f10b";
    color: white !important;
}
.ag-theme-balham .ag-icon-expanded:before {
    content: "\f10f";
    color: white !important;
}
.ag-theme-balham .ag-icon-menu {
    color: white !important;
}

.ag-theme-balham {
    --ag-font-family: Inter;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #011469 !important;
    --ag-foreground-color: black !important;
}
.ag-theme-balham-dark {
    --ag-font-family: Inter;
    --ag-foreground-color: white;
    --ag-background-color: #141E45;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #011469;
    --ag-odd-row-background-color: #001233;
    --ag-header-column-resize-handle-color: white;
}
@media (min-width: 768px) {
     ::ng-deep.mat-dialog-content {
        min-width: 1050px !important;
    }
}

.mat-select-panel {
    transform: translate(16px, 15px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    height: 110px;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54) !important;
}

.red-snackbar {
    color: black !important;
    background-color: #fff !important;
}

.mat-select-panel {
    transform: translate(16px, 15px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    height: 220px;
}


/* ag-Grid Row rules definition classes */

.row-inactive {
    background-color: rgba(255, 0, 0, 0.596) !important;
}

.row-nouse {
    background-color: rgba(255, 0, 0, 0.596) !important;
}

.row-breach {
    background-color: lightcoral !important;
}

.row-warning {
    background-color: sandybrown !important;
}

.row-ignore {
    background-color: yellowgreen !important;
}

.legend-info {
    padding-right: 15px;
}

.legend-text {
    padding: 5px;
}

.inActiveLinkedField {
    border: 1.5px solid #589bf8 !important;
}


/* ag-Grid Row rules definition classes */


/* Mat Select Dropdown Width */

.mat-select-panel {
    margin-top: 20px;
    background: #fff;
    max-height: 300px;
    max-width: 100% !important;
    width:auto;
}

::ng-deep .mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    font-size: inherit !important;
    line-height: 1.5em !important;
}

::ng-deep .mat-option {
    white-space: unset !important;
}

/* .widestdrop {
    max-width: 140% !important;
    max-width: fit-content !important;
} */


/* css for Email mat-chips */

.example-chip-list {
    width: 100%;
}

.invalid.mat-input-element {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: red;
    color: black
}

.valid.mat-input-element {
    color: black
}

.invalid.mat-chip {
    background-color: #e0e0e0 !important;
}

.valid.mat-chip {
    background-color: #e0e0e0 !important;
}


/* css for Email mat-chips */