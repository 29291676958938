////
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @requires {mixin} keyframes
@mixin scale-out-center {
    @include keyframes (scale-out-center) {
        0% {
            transform: scale(1);
            opacity: 1;
        }

        100% {
            transform: scale(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-bl {
    @include keyframes (scale-out-bl) {
        0% {
            transform: scale(1);
            transform-origin: 0% 100%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 0% 100%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-vertical {
    @include keyframes (scale-out-vertical) {
        0% {
            transform: scaleY(1);
            opacity: 1;
        }

        100% {
            transform: scaleY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-top {
    @include keyframes (scale-out-top) {
        0% {
            transform: scale(1);
            transform-origin: 50% 0%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 50% 0%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-left {
    @include keyframes (scale-out-left) {
        0% {
            transform: scale(1);
            transform-origin: 0% 50%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 0% 50%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-ver-top {
    @include keyframes (scale-out-ver-top) {
        0% {
            transform: scaleY(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }

        100% {
            transform: scaleY(0);
            transform-origin: 100% 0%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-tr {
    @include keyframes (scale-out-tr) {
        0% {
            transform: scale(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 100% 0%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-tl {
    @include keyframes (scale-out-tl) {
        0% {
            transform: scale(1);
            transform-origin: 0 0;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-ver-bottom {
    @include keyframes (scale-out-ver-bottom) {
        0% {
            transform: scaleY(1);
            transform-origin: 0% 100%;
            opacity: 1;
        }

        100% {
            transform: scaleY(0);
            transform-origin: 0% 100%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-right {
    @include keyframes (scale-out-right) {
        0% {
            transform: scale(1);
            transform-origin: 100% 50%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 100% 50%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-horizontal {
    @include keyframes (scale-out-horizontal) {
        0% {
            transform: scaleX(1);
            opacity: 1;
        }

        100% {
            transform: scaleX(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-br {
    @include keyframes (scale-out-br) {
        0% {
            transform: scale(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 100% 100%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-hor-left {
    @include keyframes (scale-out-hor-left) {
        0% {
            transform: scaleX(1);
            transform-origin: 0 0;
            opacity: 1;
        }

        100% {
            transform: scaleX(0);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-bottom {
    @include keyframes (scale-out-bottom) {
        0% {
            transform: scale(1);
            transform-origin: 50% 100%;
            opacity: 1;
        }

        100% {
            transform: scale(0);
            transform-origin: 50% 100%;
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-out-hor-right {
    @include keyframes (scale-out-hor-right) {
        0% {
            transform: scaleX(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }

        100% {
            transform: scaleX(0);
            transform-origin: 100% 100%;
            opacity: 1;
        }
    }
}
