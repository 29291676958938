/// @group themes
/// @access private
@mixin igx-vhelper {
    $left: if-ltr(left, right);
    $right: if-ltr(right, left);

    %vhelper-display {
        display: block;
        overflow: auto;
        z-index: 10001;
    }

    %vhelper--vertical {
        position: absolute;
        top: 0;
        #{$right}: 0;
    }

    %vhelper--horizontal {
        width: 100%;
    }

    %vhelper-content--vertical {
        width: 1px;
    }

    %vhelper-content--horizontal {
        height: 1px;
    }
}

@include b(igx-vhelper) {
    @include m(vertical) {
        @extend %vhelper-display !optional;
        @extend %vhelper--vertical !optional;

        @include e(placeholder-content) {
            @extend %vhelper-content--vertical !optional;
        }
    }

    @include m(horizontal) {
        @extend %vhelper-display !optional;
        @extend %vhelper--horizontal !optional;

        @include e(placeholder-content) {
            @extend %vhelper-content--horizontal !optional;
        }
    }
}
