@import 'grid-print';

/// Defines printing styles for all components in the library.
/// @group print
/// @requires {mixin} igx-grid-print
@mixin igx-print-layout {
    @media print {
        html,
        body,
        app-root {
            min-height: 100vh;
            min-width: 100vw;
            margin: 0;
            display: block;
        }

        * {
            background: transparent !important;
            background-color: transparent !important;
            color: #000 !important;
            box-shadow: none !important;
            text-shadow: none !important;
            -webkit-print-color-adjust: exact;
        }

        a[href^='http']::after {
            content: '[' attr(href) ']';
            color: blue;
        }

        .igx-no-print,
        igx-nav-drawer {
            display: none !important;
        }

        @include igx-grid-print();

        %igx-bottom-nav-menu,
        %igx-group-display,
        %igx-ripple-display,
        %igx-slider-display,
        %igx-snackbar-display,
        %form-group-display,
        %cbx-display,
        %radio-display,
        %tooltip-display,
        %igx-toast-display,
        %igx-navbar-display,
        %vhelper-display,
        %igx-button--flat,
        %igx-button--raised,
        %igx-button--fab,
        %igx-button--icon,
        %igx-button--disabled,
        %igx-tabs__header,
        %switch-display,
        %grid-tbody-scrollbar,
        %grid__cbx-selection,
        %circular-display,
        %linear-display,
        .igx-carousel__indicators,
        .igx-carousel__arrow--prev,
        .igx-carousel__arrow--next {
            display: none !important;
        }
    }
}
