%display-container {
    display: inherit;
    position: relative;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
}

%display-container--inactive {
    width: 100%;
}

@include b(igx-display-container) {
    @extend %display-container !optional;

    @include m(inactive) {
        @extend %display-container--inactive !optional;
    }
}
