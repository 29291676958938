////
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @type Timing Function
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);
/// @type Timing Function
$ease-in-cubic: cubic-bezier(.55, .55, .675, .19);
/// @type Timing Function
$ease-in-quart: cubic-bezier(.895, .03, .685, .22);
/// @type Timing Function
$ease-in-quint: cubic-bezier(.755, .05, .855, .06);
/// @type Timing Function
$ease-in-sine: cubic-bezier(.47, 0, .745, .715);
/// @type Timing Function
$ease-in-expo: cubic-bezier(.95, .05, .795, .035);
/// @type Timing Function
$ease-in-circ: cubic-bezier(.95, .05, .795, .035);
/// @type Timing Function
$ease-in-back: cubic-bezier(.95, .05, .795, .035);
/// @type Timing Function
$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
/// @type Timing Function
$ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
/// @type Timing Function
$ease-out-quart: cubic-bezier(.165, .84, .44, 1);
/// @type Timing Function
$ease-out-quint: cubic-bezier(.23, 1, .32, 1);
/// @type Timing Function
$ease-out-sine: cubic-bezier(.39, .575, .565, 1);
/// @type Timing Function
$ease-out-expo: cubic-bezier(.19, 1, .22, 1);
/// @type Timing Function
$ease-out-circ: cubic-bezier(.075, .82, .165, 1);
/// @type Timing Function
$ease-out-back: cubic-bezier(.175, .885, .32, 1.275);
/// @type Timing Function
$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
/// @type Timing Function
$ease-in-out-cubic: cubic-bezier(.645, .045, .355, 1);
/// @type Timing Function
$ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);
/// @type Timing Function
$ease-in-out-quint: .5s cubic-bezier(.86, 0, .07, 1);
/// @type Timing Function
$ease-in-out-sine: cubic-bezier(.445, .05, .55, .95);
/// @type Timing Function
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
/// @type Timing Function
$ease-in-out-circ: cubic-bezier(.785, .135, .15, .86);
/// @type Timing Function
$ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55);
