////
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @requires {mixin} keyframes
@mixin scale-up-center {
    @include keyframes(scale-up-center) {
        0% {
            transform: scale(.5)
        }

        100% {
            transform: scale(1)
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-bl {
    @include keyframes(scale-up-bl) {
        0% {
            transform: scale(.5);
            transform-origin: 0% 100%;
        }

        100% {
            transform: scale(1);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-ver-center {
    @include keyframes(scale-up-ver-center) {
        0% {
            transform: scaleY(.4);
        }

        100% {
            transform: scaleY(1);
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-top {
    @include keyframes(scale-up-top) {
        0% {
            transform: scale(.5);
            transform-origin: 50% 0%;
        }

        100% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-left {
    @include keyframes(scale-up-left) {
        0% {
            transform: scale(.5);
            transform-origin: 0% 50%;
        }

        100% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-ver-top {
    @include keyframes(scale-up-ver-top) {
        0% {
            transform: scaleY(.4);
            transform-origin: 100% 0%;
        }

        100% {
            transform: scaleY(1);
            transform-origin: 100% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-tr {
    @include keyframes(scale-up-tr) {
        0% {
            transform: scale(.5);
            transform-origin: 100% 0%;
        }

        100% {
            transform: scale(1);
            transform-origin: 100% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-tl {
    @include keyframes(scale-up-tl) {
        0% {
            transform: scale(.5);
            transform-origin: 0% 0%;
        }

        100% {
            transform: scale(1);
            transform-origin: 0% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-ver-bottom {
    @include keyframes(scale-up-ver-bottom) {
        0% {
            transform: scaleY(.4);
            transform-origin: 0% 100%;
        }

        100% {
            transform: scaleY(1);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-right {
    @include keyframes(scale-up-right) {
        0% {
            transform: scale(.5);
            transform-origin: 100% 50%;
        }

        100% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-hor-center {
    @include keyframes(scale-up-hor-center) {
        0% {
            transform: scaleX(.4);
        }

        100% {
            transform: scaleX(1);
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-br {
    @include keyframes(scale-up-br) {
        0% {
            transform: scale(.5);
            transform-origin: 100% 100%;
        }

        100% {
            transform: scale(1);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-hor-left {
    @include keyframes(scale-up-hor-left) {
        0% {
            transform: scaleX(.4);
            transform-origin: 0% 0%;
        }

        100% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-bottom {
    @include keyframes(scale-up-bottom) {
        0% {
            transform: scale(.5);
            transform-origin: 50% 100%;
        }

        100% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-up-hor-right {
    @include keyframes(scale-up-hor-right) {
        0% {
            transform: scaleX(.4);
            transform-origin: 100% 100%;
        }

        100% {
            transform: scaleX(1);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-center {
    @include keyframes(scale-down-center) {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(.5);
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-bl {
    @include keyframes(scale-down-bl) {
        0% {
            transform: scale(1);
            transform-origin: 0% 100%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-ver-center {
    @include keyframes(scale-down-ver-center) {
        0% {
            transform: scaleY(1);
        }

        100% {
            transform: scaleY(.3);
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-top {
    @include keyframes(scale-down-top) {
        0% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 50% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-left {
    @include keyframes(scale-down-left) {
        0% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 0% 50%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-ver-top {
    @include keyframes(scale-down-ver-top) {
        0% {
            transform: scaleY(1);
            transform-origin: 100% 0%;
        }

        100% {
            transform: scaleY(.3);
            transform-origin: 100% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-tr {
    @include keyframes(scale-down-tr) {
        0% {
            transform: scale(1);
            transform-origin: 100% 0%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 100% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-tl {
    @include keyframes(scale-down-tl) {
        0% {
            transform: scale(1);
            transform-origin: 0% 0%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 0% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-ver-top-bottom {
    @include keyframes(scale-down-ver-to-bottom) {
        0% {
            transform: scaleY(1);
            transform-origin: 0% 100%;
        }

        100% {
            transform: scaleY(.3);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-right {
    @include keyframes(scale-down-right) {
        0% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 100% 50%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-hor-center {
    @include keyframes(scale-down-hor-center) {
        0% {
            transform: scaleX(1);
        }

        100% {
            transform: scaleX(.3);
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-br {
    @include keyframes(scale-down-br) {
        0% {
            transform: scale(1);
            transform-origin: 100% 100%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-hor-left {
    @include keyframes(scale-down-hor-left) {
        0% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
        }

        100% {
            transform: scaleX(.3);
            transform-origin: 0% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-bottom {
    @include keyframes(scale-down-bottom) {
        0% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }

        100% {
            transform: scale(.5);
            transform-origin: 50% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin scale-down-hor-right {
    @include keyframes(scale-down-hor-right) {
        0% {
            transform: scaleX(1);
            transform-origin: 100% 100%;
        }

        100% {
            transform: scaleX(.3);
            transform-origin: 100% 100%;
        }
    }
}

/// @param {number} $start-scale [.5] - The scale to start the animation from.
/// @param {number} $end-scale [1] - The scale to end the animation at.
/// @requires {mixin} keyframes
@mixin scale-in-out($start-scale: .5, $end-scale: 1) {
    @include keyframes(scale-in-out) {
        50% {
            transform: scale($start-scale);
            transform-origin: 50% 50%;
        }

        100% {
            transform: scale($end-scale);
            transform-origin: 50% 50%;
        }
    }
}
