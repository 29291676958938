////
/// @group typography
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

// Import the typography mixins from component themes
// with typography support.
@import '../components/bottom-nav/bottom-nav-theme';
@import '../components/button/button-theme';
@import '../components/calendar/calendar-theme';
@import '../components/card/card-theme';
@import '../components/charts/data-chart-theme';
@import '../components/charts/financial-chart-theme';
@import '../components/charts/funnel-chart-theme';
@import '../components/charts/gauge-theme';
@import '../components/charts/graph-theme';
@import '../components/charts/shape-chart-theme';
@import '../components/checkbox/checkbox-theme';
@import '../components/chip/chip-theme';
@import '../components/column-hiding/column-hiding-theme';
@import '../components/date-range-picker/date-range-picker-theme';
@import '../components/dialog/dialog-theme';
@import '../components/drop-down/drop-down-theme';
@import '../components/expansion-panel/expansion-panel-theme';
@import '../components/grid/excel-filtering-theme';
@import '../components/input/input-group-theme';
@import '../components/list/list-theme';
@import '../components/navbar/navbar-theme';
@import '../components/navdrawer/navdrawer-theme';
@import '../components/radio/radio-theme';
@import '../components/slider/slider-theme';
@import '../components/snackbar/snackbar-theme';
@import '../components/switch/switch-theme';
@import '../components/tabs/tabs-theme';
@import '../components/time-picker/time-picker-theme';
@import '../components/toast/toast-theme';
@import '../components/tooltip/tooltip-theme';

/// @access private
@mixin _igx-typography-styles($font-family, $type-scale) {
    font-family: unquote($font-family);
    font-size: $browser-context;
    line-height: $browser-line-height;
    font-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    $this: bem--selector-to-string(&);
    $selector: bem--extract-first-selector($this);

    // Maps type scale typographic categories
    // to native elements.
    $category-element-map: (
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body-1: 'p'
    );

    @each $category, $type-style in $type-scale {
        // Get the native element that uses typographic styles directly
        // as mapped in the $category-element-map
        $e: map-get($category-element-map, $category);

        // Create a placeholder selector with styles for each
        // typographic style to be able to easily extend it
        // elsewhere.
        %#{$category} {
            @include igx-type-style($type-scale, $category);
        }

        // Add native element typographic styles.
        @if $e != null {
            #{$e} {
                @extend %#{$category};
            }
        }

        // Add class selector typographic styles.
        @if ($selector == '.igx-typography') {
            @include e(#{$category}) {
                @extend %#{$category};
            }
        }
    }

    // Call the individual component styles with the type scale
    @include _excel-filtering-typography($type-scale);
    @include igx-banner-typography($type-scale);
    @include igx-bottom-nav-typography($type-scale);
    @include igx-button-typography($type-scale);
    @include igx-calendar-typography($type-scale);
    @include igx-card-typography($type-scale);
    @include igx-checkbox-typography($type-scale);
    @include igx-chip-typography($type-scale);
    @include igx-column-hiding-typography($type-scale);
    @include igx-data-chart-typography($type-scale);
    @include igx-date-range-typography($type-scale);
    @include igx-dialog-typography($type-scale);
    @include igx-drop-down-typography($type-scale);
    @include igx-expansion-panel-typography($type-scale);
    @include igx-financial-chart-typography($type-scale);
    @include igx-funnel-chart-typography($type-scale);
    @include igx-gauge-typography();
    @include igx-graph-typography();
    @include igx-input-group-typography($type-scale);
    @include igx-list-typography($type-scale);
    @include igx-navbar-typography($type-scale);
    @include igx-navdrawer-typography($type-scale);
    @include igx-radio-typography($type-scale);
    @include igx-shape-chart-typography($type-scale);
    @include igx-slider-typography($type-scale);
    @include igx-snackbar-typography($type-scale);
    @include igx-switch-typography($type-scale);
    @include igx-tabs-typography($type-scale);
    @include igx-time-picker-typography($type-scale);
    @include igx-toast-typography($type-scale);
    @include igx-tooltip-typography($type-scale);
}

/// Adds typography styles for h1-h6, paragraph and creates
/// custom typography class selectors. The produces styles
/// are based on the passed type scale. If omitted the
/// default-type-scale will be used.
/// @access public
/// @param {String} $font-family ["'Titillium Web', sans-serif"] - The font family to be used across all typographic elements.
/// @param {Map} $type-scale [$default-type-scale] - A type scale map as produced by igx-type-scale.
@mixin igx-typography(
    $font-family: "'Titillium Web', sans-serif",
    $type-scale: $default-type-scale,
) {
    @if(is-root()) {
        // Use the BEM notation to create a link
        // between the igx-typography class selector
        // and all typographic elements.
        @include b(igx-typography) {
            @include _igx-typography-styles($font-family, $type-scale);
        }
    } @else {
        @include _igx-typography-styles($font-family, $type-scale);
    }
}

